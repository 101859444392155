@if (configService.configState$ | async; as config) {
  <div class="header">
    <!-- Router link here is default ["/"] -->
    <spb-logo title="Spärrtjänst"></spb-logo>
    <div [style.flex-grow]="1"></div>
    @if (config.isReady) {
      @if (configService.currentUser$ | async; as user) {
        <div class="menu">
          <span>{{ user.name }}</span>
          <button [matMenuTriggerFor]="menu" mat-button>
            <mat-icon>menu</mat-icon>
          </button>
        </div>
      }
    }
  </div>

  <!-- Add other fancy navigations here -->
  <mat-menu #menu="matMenu">
    <button [routerLink]="['/main']" mat-menu-item>Spärra bankid</button>
    @if (config.isAdmin) {
      <button [routerLink]="['/admin']" mat-menu-item>Admin tjänster</button>
    }
    <button (click)="configService.logout()" mat-menu-item>Logga ut</button>
  </mat-menu>
} @else {
  <div class="header">
    <spb-logo title="Spärrtjänst"></spb-logo>
  </div>
}
