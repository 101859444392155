import {AsyncPipe} from '@angular/common'
import {Component, inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatIcon} from '@angular/material/icon'
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu'
import {RouterLink} from '@angular/router'
import {LogoComponent} from '@sparbanken-syd/sparbanken-syd-theme'
import {ConfigService} from '../../services/config.service'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  imports: [
    AsyncPipe,
    MatMenuTrigger,
    MatIcon,
    MatMenu,
    MatMenuItem,
    MatButton,
    LogoComponent,
    RouterLink
  ],
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  public configService = inject(ConfigService)
}
