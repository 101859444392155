import {Routes} from '@angular/router'
import {authGuard} from './application/auth.guard'

export const routes: Routes = [
  {
    path: 'main',
    loadComponent: () => import('./main/block/block.component')
      .then(m => m.BlockComponent),
    canActivate: [authGuard]
  },
  {
    path: 'login',
    loadComponent: () => import('./login/login/login.component')
      .then(m => m.LoginComponent)
  },
  {
    path: 'admin',
    loadComponent: () => import('./admin/home/admin.component')
      .then(m => m.AdminComponent),
    canActivate: [authGuard]
  },
  {
    path: '**',
    redirectTo: 'main',
    pathMatch: 'full'
  }
]